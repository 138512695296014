import ApiService from "@/core/services/ApiService";

const endpoints = {
    default: {
        auth_change_password: "auth/change-password",
        video: "video",
        video_group: "video/group",
        statistic: "statistics",
        directory: "directory",
        directory_tree: "directory/tree",
        trash: "trash",
        trash_recover: "trash/recover"
    },
    developer: {
        customer: "customer",
        customer_group: "customer/group",
        customer_company: "customer/company",
        customer_self_login: "customer/self-login",
        storage: "storage",
        video_management_downloader: "video-management/downloader",
        video_management_converter: "video-management/converter",
        video_management_migrator: "video-management/migrator"
    },
    system: {
        log_register: "log-register"
    }
}

class EndpointService {
    static activeEndpoints = {};
    static apiConfig = {
        default: process.env.VUE_APP_API_PATH,
        developer: process.env.VUE_APP_DEVELOPER_API_PATH,
        system: process.env.VUE_APP_SYSTEM_API_PATH
    };

    static get(endpointKey, groupKey = 'default') {
        ApiService.setBaseUrl(this.apiConfig[groupKey] ?? process.env.VUE_APP_API_PATH);
        return this.activeEndpoints[groupKey] && this.activeEndpoints[groupKey][endpointKey] ? this.activeEndpoints[groupKey][endpointKey] : null;
    }

    static setActiveEndpoints(groupKey) {
        let groups = ['default', 'system', groupKey];

        for (let key in endpoints) {
            if (groups.includes(key)) {
                this.activeEndpoints[key] = endpoints[key];
            }
        }
    }

}

export default EndpointService;
