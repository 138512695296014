const APP_PREFIX = "app.";
const CRM_URL = process.env.VUE_APP_CRM_URL;

let app = {};
app[APP_PREFIX + 'url'] = process.env.VUE_APP_URL;

const get = (key) => {
    let data = app[APP_PREFIX + key];

    if(CRM_URL) {
        data = window.localStorage.getItem(APP_PREFIX + key)
    }

    return data;
}

export const getApp = (key) => {
    if(key == 'apiUrl') {
        return get('url') + process.env.VUE_APP_API_PATH;
    } else {
        return get(key);
    }
};

export const saveApp = (key, data) => {
  window.localStorage.setItem(APP_PREFIX + key, data);
};

export const destroyApp = (key) => {
  window.localStorage.removeItem(APP_PREFIX + key);
};

export default { getApp, saveApp, destroyApp };
