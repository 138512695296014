<template>
    <router-view/>
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";

@import "assets/css/app.css";

</style>

<script>
import {Actions, Mutations} from "@/store/enum/StoreEnums";

export default {
    name: "app",
    data() {
        return {
            appCrmStatus: !!process.env.VUE_APP_CRM_URL,
            loadedInitialize: false,
            filterWithUrl: true
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    created(){
        this.$store.dispatch(Actions.VERIFY_AUTH).then((response) => {
            if(response && response.status && !this.loadedInitialize) {
                this.initialize();
            }
        });
    },
    mounted() {
        this.$store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        if(this.$store.getters.isUserAuthenticated && !this.loadedInitialize){
            this.initialize();
        }
    },
    methods: {
        initialize() {
            this.loadedInitialize = true;
        },
        getFilterWithUrl(value){
            if(this.filterWithUrl && Object.keys(this.urlParams()).length){
                value = this.urlParams();
            }

            return value;
        }
    }
}
</script>