import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store";
import { Actions } from "@/store/enum/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { getApp } from "@/core/services/App";


/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    static axios;

    /**
     * @description initialize vue axios
     */
    static init(app) {
        app.use(VueAxios, axios);

        this.axios = app.axios;
        this.axios.defaults.baseURL = getApp('apiUrl');

        this.axios.interceptors.request.use((config) => {
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        this.axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status == 401 && error.response.data.message != "invalid_credentials") {
                store.dispatch(Actions.LOGOUT);
                window.location.href = '/login';
            }

            return Promise.reject(error);
        })
    }

    static setHeader() {
        this.axios.defaults.headers.common["Authorization"] = JwtService.get('tokenType') + ' ' + JwtService.get('token');
    }

    static setBaseUrl(baseUrl){
        this.axios.defaults.baseURL = getApp('url') + baseUrl;
    }
}

export default ApiService;
