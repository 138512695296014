import {createApp} from 'vue'
import App from './App.vue'

import router from './router'
import {store} from './store'
import ElementPlus from "element-plus";
import elementPLusLocaleTR from "element-plus/lib/locale/lang/tr";
import i18n from "@/core/plugins/vue-i18n";
import Validation from "@/core/helpers/validation";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";
import {initGlobal} from "@/core/plugins/global";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";

const app = createApp(App);

app.use(router);
app.use(ElementPlus, {
    locale: elementPLusLocaleTR,
});
app.use(i18n);

store._vm = app.config.globalProperties;
app.use(store);

ApiService.init(app);
Validation.init(app);

initApexCharts(app);
initInlineSvg(app);
initGlobal(app);
initVeeValidate();

app.mount("#app");
