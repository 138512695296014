import {createStore} from 'vuex'

import AuthModule from "@/store/module/AuthModule";
import BodyModule from "@/store/module/BodyModule";
import BreadcrumbsModule from "@/store/module/BreadcrumbsModule";
import ConfigModule from "@/store/module/ConfigModule";

//Default
import Video from "@/store/video";
import VideoGroup from "@/store/video/group";
import Statistic from "@/store/statistic";
import Directory from "@/store/directory";
import Trash from "@/store/trash";

//Developer
import DeveloperCustomer from "@/store/developer/customer";
import DeveloperCustomerGroup from "@/store/developer/customer/group";
import DeveloperCustomerCompany from "@/store/developer/customer/company";
import DeveloperStorage from "@/store/developer/storage";
import DeveloperVideoManagementDownloader from "@/store/developer/videomanagement/downloader";
import DeveloperVideoManagementConverter from "@/store/developer/videomanagement/converter";
import DeveloperVideoManagementMigrator from "@/store/developer/videomanagement/migrator";

export const store = createStore({
    modules: {
        AuthModule,
        BodyModule,
        BreadcrumbsModule,
        ConfigModule,
        video: Object.assign(Video, {
            modules: {
                group: VideoGroup
            }
        }),
        directory: Directory,
        statistic: Statistic,
        trash: Trash,
        developer: {
            namespaced: true,
            modules: {
                customer: Object.assign(DeveloperCustomer, {
                    modules: {
                        group: DeveloperCustomerGroup,
                        company: DeveloperCustomerCompany
                    }
                }),
                storage: DeveloperStorage,
                videoManagement: {
                    namespaced: true,
                    modules: {
                        downloader: DeveloperVideoManagementDownloader,
                        converter: DeveloperVideoManagementConverter,
                        migrator: DeveloperVideoManagementMigrator,
                    }
                }
            }
        }
    }
});


export default store;