export const createTableState = (defaultValue = []) => {
    return {
        loading: false,
        data: defaultValue,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    }
}

export const normalizePagination = (meta) => {
    return {
        current: meta.current_page,
        pageSize: meta.per_page,
        total: meta.total_record,
    }
}