// TR
export const locale = {
    common: {
        edit: "Düzenle",
        yes: "Evet",
        no: "Hayır",
        new: "Yeni",
        back: "Geri",
        language: "Dil",
        currency: "Para Birimi",
        cancel: "Vazgeç",
        send: "Gönder",
        confirm: "Devam Et",
        date: "Tarih",
        save: "Kaydet",
        report: "Bildir",
        active: "Aktif",
        passive: "Pasif",
        remove: "Sil",
        action: "İşlem",
        search: "Ara",
        close: "Kapat",
        preview: "Önizleme",
        today: "Bugün",
        weekly: "Haftalık",
        monthly: "Aylık",
        yearly: "Yıllık",
        viewAll: "Tümü",
        notification: "Bildirim",
        download: "İndir",
        optional: "Opsiyonel",
        permission: "Yetki",
        manager: "Yönetici",
        default: "Varsayılan",
        status: "Durum",
        sort: "Sıra",
        createdAt: "Oluşturulma Tarihi",
        ipAddress: "İp Adresi",
        userAgent: "User Agent",
        image: "Resim",
        approved: "Onaylı",
        chooseSelect: "Seçim yapın.",
        chooseDate: "Tarih seçimi yapın.",
        chooseDateAndTime: "Tarih ve saat seçimi yapın.",
        individual: "Bireysel",
        corporate: "Kurumsal",
        cash: "Peşin",
        system: "Sistem",
        export: "Dışa Aktar",
        overview: "Genel Bakış",
        settings: "Ayarlar",
        id: "ID",
        chooseFile: "Dosya seçimi yapın.",
        total: "Toplam",
        inProcess:"İşlemde",
        pending: "Bekleyen",
        completed: "Tamamlanan",
        progress: "İlerleme",
        used: "Kullanılan",
        fileUpload: "Dosya Yükle"
    },
    menu: {
        dashboard: "Gösterge Paneli",
        accountManagement: "Hesap Yönetimi",
        contentManagement: {
            title: "İçerik Yönetimi",
            video: {
                title: "Videolar",
                directory: "Video Klasör Görünüm",
                group: "Video Grupları",
                upload: "İçerik Yükle"
            },
            trash: "Çöp Kutusu"
        },
        sourceManagement: {
            title: "Kaynak Yönetimi",
            analytic: "Analitik",
            storage: "Depolama Alanı",
            trafficUsage: "Trafik Kullanımı"
        },
        subscriptionManagement: {
            title: "Abonelik Yönetimi",
            information: "Abonelik Bilgileri",
            apiManagement: "Api Yönetimi"
        },
        developerManagement: {
            title: "Yönetici İşlemleri",
            customerManagement: {
                title: "Müşteri Yönetimi",
                company: "Firmalar",
                customer: "Müşteriler"
            },
            storage: "Depolama Alanları",
            videoManagement: {
                title: "Video Yönetimi",
                downloader: "Video İndirme Görevi",
                converter: "Video Dönüştürme Görevi",
                migrate: "Video Migrate Görevi",
            },
        }
    },
    messages: {
        warning: "Uyarı",
        success: "Başarılı",
        error: "Hata",
        sureAction: "İşlemi yapmak istediğinize emin misiniz?",
        sureConfirm: "Onaylamak istediğinize emin misiniz?",
        sureDelete: "Silme işlemi yapmak istediğinize emin misiniz?",
        actionOk: "İşleminiz başarılı bir şekilde tamamlanmıştır.",
        deleteOk: "Silme işleminiz başarılı bir şekilde tamamlanmıştır.",
        selectRow: "Lütfen bir kayıt seçiniz!",
        wait: "Lütfen Bekleyiniz!",
        loading: "Yükleniyor",
        noResultFound: "Sonuç bulunamadı!"
    },
    btn: {
        new: "Yeni",
        add: "Ekle",
        create: "Oluştur",
        copy: "Kopyala",
        edit: "Düzenle",
        delete: "Sil",
        yes: "Evet",
        no: "Hayır",
        cancel: "Vazgeç",
        close: "Kapat",
        refresh: "Yenile",
        update: "Güncelle",
        filter: "Filtrele",
        sort: "Sırala",
        save: "Kaydet",
        saveAll: "Tümünü Kaydet",
        select: "Seç",
        selectFile: "Dosya Seç",
        upload: "Yükle",
        download: "İndir",
        action: "İşlem",
        previous: "Önceki",
        ensuing: "Sonraki",
        next: "İleri",
        back: "Geri",
        send: "Gönder",
        ok: "Tamam",
        clear: "Temizle",
        tryAgain: "Tekrar Deneyin!",
        apply: "Uygula",
        reset: "Sıfırla"
    },
    validation: {
        required: "Bu alan gereklidir.",
        email: "Bu alan geçerli bir e-posta olmalıdır.",
        number: "Bu alan geçerli bir sayı olmalıdır.",
        confirmed: "Bu alan onayı eşleşmiyor.",
        length: "Bu alanda 2 seçenek olmalıdır.",
        password: "Lütfen geçerli bir şifre girin.",
        passwordConfirmation: "Lütfen şifreyi tekrar girin.",
        dontMatchPassword: "Şifre alanları birbiriyle eşleşmiyor!"
    },
    pages: {
        dashboard: {
            statistic: {
                general: {
                    videoCount: "Toplam Video Sayısı",
                    hlsCount: "Toplam HLS Sayısı",
                    videoGroupCount: "Toplam Video Grup Sayısı"
                }
            },
            usedStatistic: {
                title: "Kullanım İstatistikleri",
                type: {
                    video: "Yüklenen İçerik",
                    image: "Video Görselleri",
                    stream: "Video Dönüşümleri",
                },
                noDataDescription: "Bu disk için kullanım istatistiği bulunmuyor."
            }
        },
        account: {
            overview: {
                title: "Profil Detayları",
                cols: {
                    name: "Ad",
                    surname: "Soyad",
                    email: "E - Posta",
                    phone: "Telefon",
                }
            },
            settings: {
                signInMethod: {
                    title: "Oturum Açma Yöntemi",
                    emailAddress: "E - Posta Adresi",
                    password: {
                        title: "Şifre",
                        cols: {
                            currentPassword: "Şuanki Şifre",
                            password: "Şifre",
                            passwordConfirmation: "Şifre Tekrar",
                        },
                        changePassword: "Şifre Değiştir",
                        updatePassword: "Şifreyi Güncelle",
                        responseMessages: {
                            currentPasswordInvalid: "Şuanki şifre geçersiz."
                        }
                    }
                }
            }
        },
        login: {
            title: "viStream Yönetim Paneli",
            cols: {
                customerID: "Müşteri Numarası",
                username: "Kullanıcı Adı",
                email: "E-Posta Adresi",
                password: "Şifre",
                loginConfirmation: "Giriş yaparak viStream Yönetim Paneli üzerinden gerçekleştireceğim tüm işlemlerin IP Adresim ve Kullanıcı Bilgilerim eşlenerek kayıt altına alınacağını kabul ediyorum."
            },
            successLogin: "Başarıyla giriş yaptınız!",
            btnText: "Giriş Yap",
            responseMessages: {
                invalidCredentials: "Kullanıcı Adı veya Şifre yanlış!"
            }
        },
        video: {
            title: "Video",
            subTitle: "Video Listesi",
            new: "Yeni Video",
            edit: "Video Düzenle",
            images: "Resimler",
            playVideo: "Video'yu oynat",
            videoTab:{
                original: 'Orjinal',
                hls: 'HLS'
            },
            cols: {
                information: "Video Bilgiler",
                detail:"Diğer Detaylar",
                group: "Grup",
                extension: "Uzantı",
                owner: "Sahibi"
            },
            detail: {
                title: "Video Detayı",
                deleteContent: "İçeriği Sil",
                tab: {
                    general: "Detay",
                    image: "Resimler",
                    video: "Video"
                },
                cols: {
                    title: "Başlık",
                    group: "Video Grubu"
                },
                options: {
                    title: "İşlem Parametreleri",
                    type: {
                        convert_mp4: "MP4 haricindekileri dönüştür",
                        convert_unsupported: "Desteklenmeyenleri MP4'e çevir",
                        convert_hls: "HLS için export oluştur."
                    }
                },
                processState: {
                    title: "İşlem Durumu",
                    type: {
                        CONVERT_HLS: "HLS Dönüşümü",
                        CONVERT_COMPRESSION: "Video Sıkıştırma"
                    }
                },
                extras: {
                    title: "Diğer Bilgiler",
                    sizeChanged: {
                        old: "Orjinal (%s)"
                    }
                },
                copyUrl: {
                    title: "Direk Erişim URL",
                    copied: "Kopyalandı!"
                },
                stream: {
                    title: "Desteklenen Yayınlar",
                    subTitle: "Desteklenen Yayın Listesi",
                    cols: {
                        type: "Tip",
                        size: "Boyut",
                        url: "Url"
                    }
                },
                videoImage: {
                    title: "Video Resimleri",
                },
                warning: {
                    videoCompress: {
                        text1: "Video Sıkıştırma işlemi devam ediyor. Bu süreç tamamlandıktan sonra görüntülenen video bilgileri(direk erişim url, thumbnail vb.) değişiklik gösterilebilir. Video sıkıştırma işlemi tamamlandıktan sonra nihai bilgiler gösterilecektir.",
                        text2: "Video sıkıştırma işlemi sunucu yoğunluğu, video renk kodlaması vb. gibi durumlara bağlı olarak birkaç saat kadar sürebilir.",
                        videoLink: "Video sıkıştırma işlemi yapıldıktan sonra URL gösterilecektir."
                    }
                }
            },
            group: {
                title: "Video Grupları",
                subTitle: "Video Grup Listesi",
                new: "Yeni Video Grubu",
                edit: "Video Grubu Düzenle",
                cols: {
                    name: "Ad",
                    contentCount:" İçerik Sayısı"
                }
            },
            upload: {
                title: "Yeni Video",
                videoTitlePattern: "%d. Video Başlığı",
                cols: {
                    video: {
                        title: "Video",
                        dragFile: "Video'yu buraya sürükleyin veya yüklemek için tıklayın"
                    },
                    group: "Video Grubu",
                    option: "Video Ayarları",
                    uploadState: "Yükleme Durumu"
                },
                option: {
                    convertMp4: "MP4 dışında olan tipleri dönüştür.",
                    convertUnsupported: "FLV, MPG, AVI, WMV dosya türlerini MP4'e çevir.",
                    convertHls: "HLS için export oluştur."
                },
                submit: "İşlemi Başlat",
                warnings: {
                    requiredFile: "Lütfen dosya seçimi yapın."
                },
            }
        },
        directory: {
            title: "Klasörler",
            subTitle: "Klasör Listesi",
            videoCount: "Video Sayı",
            new: "Yeni Klasör",
            edit: "Klasörü Düzenle",
            videoWithoutFolder: "Klasörsüz Videolar",
            cols: {
                title: "Klasör Adı"
            },
            warnings: {
                requiredDirectory: "Lütfen yükleme yapmak istediğiniz klasörü seçin. Eğer klasör yoksa önce klasör oluşturun.",
                cantMovedHomeDirectory: "Ana dizine dosya taşıması yapılamaz, lütfen klasör seçin.",
                cantMovedFileToWithoutFolder: "Dosya, Klasörsüz Videolar dizinine taşınamaz.",
                cantMovedFolderToWithoutFolder: "Klasör, Klasörsüz Videolar dizinine taşınamaz.",
                cantDeletedWithoutFolder: "Klasörsüz videolar dizini silinemez.",
                cantCreatedWithoutFolder: "Klasörsüz videolar dizinine, klasör oluşturulamaz."
            }
        },
        trash: {
            title: "Çöp Kutusu",
            subTitle: "Çöp Kutusu Listesi",
            cols: {
                information: "Video Bilgileri",
                detail: "Diğer Detaylar",
                group: "Grup",
                extension: "Uzantı",
                deletedAt: "Silinme"
            },
            warnings: {
                trash: "Silinen içerikler yanlışlıkla yapılan hataları önlemek amacıyla bu sayfada gösterilir ve 7 gün boyunca saklanır. Süre sonunda kalıcı olarak silinir. İçeriği kurtarmak veya kalıcı olarak silmek için aşağıdaki butonları kullanabilirsiniz."
            },
            messages: {
                sureRecover: "Kurtarma işlemi yapmak istediğinize emin misiniz?",
                sureDelete: "Kalıcı olarak silme işlemi yapmak istediğinize emin misiniz?",
            }
        },
        developer: {
            customerManagement: {
                customer: {
                    title: "Müşteriler",
                    subTitle: "Müşteri Listesi",
                    selfLogin: "Hesaba Giriş Yap",
                    cols: {
                        name: "Ad",
                        surname: "Soyad",
                        email: "E-Posta",
                        group: "Grup"
                    },
                    save: {
                        new: "Yeni Müşteri",
                        edit: "%s - Müşteri Düzenleme",
                        tabs: {
                            general: "Genel Detaylar",
                            storage: "Depolama"
                        },
                        cols: {
                            password: "Şifre",
                            company: "Firma"
                        },
                        action: {
                            title: "Kullanıcı İşlemleri",
                            type: {
                                is_deletable: "Kullanıcı Silinebilir",
                                is_editable: "Kullanıcı Düzenlenebilir"
                            }
                        },
                        storage: {
                            new: "Yeni Disk Ekle",
                            cols: {
                                disk: "Disk Seçimi",
                                size: "Boyut"
                            },
                            field:{
                                total: "Toplam Alan",
                                used: "Kullanılan Alan",
                                empty: "Boş Alan",
                                fullRate: "Doluluk Oranı"
                            }
                        },
                        warnings: {
                            validate: "Lütfen sekmeler arası geçiş yapıp doldurması zorunlu alanları doldurun."
                        }
                    }
                },
                company: {
                    title: "Firmalar",
                    subTitle: "Firma Listesi",
                    new: "Yeni Firma",
                    edit: "Firma Düzenle",
                    cols: {
                        name: "Ad",
                        userCount: "Kayıtlı Personel"
                    }
                },
            },
            storage: {
                title: "Depolama Alanları",
                subTitle: "Depolama Alanı Listesi",
                new: "Yeni Depolama Alanı",
                edit: "Depolama Alanı Düzenle",
                cols: {
                    name: "Ad",
                    size: "Boyut",
                    capacity: "Kapasite",
                    used: "Kullanılan",
                    progress: "İlerleme",
                    path: "Path"
                },
                warnings: {
                    path: "Depolama alanın <code>ln -s /%s/storage/ /var/www/vistream/storage/app/public/%s</code> komutu ile bağlandığından emin olunuz."
                }
            },
            videoManagement: {
                downloader: {
                    title: "Video İndirme Görevleri",
                    subTitle: "Video İndirme Görevi Listesi",
                    new: "Yeni İndirme Görevi",
                    cols: {
                        corporation: "Kurum",
                        videoGroup: "Video Grubu",
                        fileSchema: "Dosya Şeması",
                        file: "Dosya"
                    },
                    warnings: {
                        requiredFile: "Lütfen dosya seçin."
                    },
                    detail: {
                        title: "İndirme Görev Detayı",
                        subTitle: "İndirme Görev Detayı Listesi",
                        cols: {
                            title: "Başlık"
                        }
                    }
                },
                converter: {
                    title: "Video Dönüştürme Görevleri",
                    subTitle: "Video Dönüştürme Görevi Listesi",
                    new: "Yeni Dönüştürme Görevi",
                    cols: {
                        converterType: "Dönüştürme Tipi",
                        manager: "Yönetici Seçimi",
                        selectorType: "Seçiçi Tipi",
                        converterCount: "Dönüştürülecek Adet"
                    },
                    selectorType: {
                        random: "Rastgele",
                        created_asc: "İlk Yüklenenler (ASC)",
                        created_desc: "Son Yüklenenler (DESC)"
                    },
                    responseMessages: {
                        no_more_items: "Daha fazla öğe yok."
                    },
                    detail: {
                        title: "Dönüştürme Görev Detayı",
                        subTitle: "Dönüştürme Görev Detayı Listesi",
                        cols: {
                            videoID: "Video ID",
                            title: "Başlık"
                        }
                    }
                },
                migrate: {
                    title: "Video Migrate Görevleri",
                    subTitle: "Video Migrate Görevi Listesi",
                    new: "Yeni Migrate Görevi",
                    cols: {
                        host: "Host",
                        token: "Bearer Token",
                        moveManager: "Taşınacak Yöneticiler",
                        limit: "İşlem Limiti"
                    },
                    responseMessages: {
                        no_migrate_videos: "Taşınacak video yok."
                    },
                    detail: {
                        title: "Migrate Görev Detayı",
                        subTitle: "Migrate Görev Detayı Listesi",
                        cols: {
                            videoID: "Video ID",
                            title: "Başlık",
                            newVideoID: "Yeni Video ID",
                            totalSize: "Toplam Boyut"
                        }
                    }
                }
            }
        },
        preparingPage: {
            title: "Sayfa Hazırlanıyor",
            description: "Görüntülemek istediğiniz sayfa hazırlık aşamasındadır.",
        }
    },
    filters: {
        title: "Filtre Seçenekleri",
        video: {
            title: "Başlık",
            extension: "Uzantılar",
            videoGroup: "Video Grupları"
        }
    },
    sortFields: {
        title: "Sıralama Seçenekleri",
        video: {
            titleAsc: "Ada Göre (A-Z)",
            titleDesc: "Ada Göre (Z-A)",
            qualityAsc: "Çözünürlük (Küçükten Büyüğe)",
            qualityDesc: "Çözünürlük (Büyükten Küçüğe)",
            durationAsc: "Uzunluk (Küçükten Büyüğe)",
            durationDesc: "Uzunluk (Büyükten Küçüğe)",
            sizeAsc: "Boyut (Küçükten Büyüğe)",
            sizeDesc: "Boyut (Büyükten Küçüğe)",
            createdAtAsc: "Eklenme Tarihi (Eskiden Yeniye)",
            createdAtDesc: "Eklenme Tarihi (Yeniden Eskiye)",
        }
    },
    customTable: {
        noData: "Listelenecek veri bulunamadı.",
        showingPageInformationPattern: "%s kayıttan, %d ile %d arası gösteriliyor",
    },
    userMenu: {
        language: "Dil",
        accountSettings: "Hesap Ayarları",
        signOut: "Çıkış Yap"
    },
    footer: {
        copyrightText: "Tüm hakları saklıdır."
    },
    endpointActions:{
        get: "Görüntüleme",
        post: "Ekleme",
        put: "Güncelleme",
        delete: "Silme"
    },
    auth: {
        quickLink: {
            homepage: "Anasayfa",
            document: "Dokümanlar",
            contact: "İletişim"
        },
        information: {
            title: "Geleceği Önemseyen İşletmeler İçin Yeni Teknolojiler",
            description: "Rekabet avantajı sağlamak, müşteri deneyimini geliştirmek,<br>operasyonel verimliliği artırmak ve inovasyonu teşvik etmek açısından<br> yeni teknolojiler büyük önem taşımaktadır."
        },
        errors: {
            invalid_customer_id: "Müşteri numaranız hatalı, lütfen tekrar deneyiniz!",
            login_description: "Lütfen tarafınıza iletilen yetkili giriş bilgilerini kullanarak giriş yapınız.",
            login_error: "Lütfen giriş bilgilerinizi kontrol ediniz.",
        }
    }
};
