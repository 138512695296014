import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {showModal, hideModal, removeModalBackdrop} from "@/core/helpers/dom";
import Endpoint from "@/core/services/Endpoints";
import Swal from "sweetalert2/dist/sweetalert2.js";
import objectPath from "object-path";
import moment from 'moment';
import 'moment/locale/tr'
import Inputmask from 'inputmask'
import eventBus from "@/core/plugins/event-bus.js";

export function initGlobal(app) {
    app.directive('init', {
        beforeMount(el, binding, vnode) {
            if (binding.arg) {
                binding.instance[binding.arg] = binding.value;
            }
        }
    })

    app.directive('mask', {
        beforeMount(el, binding, vnode) {
            Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
        },
        unMounted(el) {
            Inputmask.remove(el);
        }
    })

    let _vm = app.config.globalProperties;

    _vm.sprintf = (pattern, params) => {
        var i = 0;
        return pattern.replace(/%(s|d|0\d+d)/g, function (x, type) {
            var value = params[i++];
            switch (type) {
                case 's':
                    return value;
                case 'd':
                    return parseInt(value, 10);
                default:
                    value = String(parseInt(value, 10));
                    var n = Number(type.slice(1, -1));
                    return '0'.repeat(n).slice(value.length) + value;
            }
        });
    };

    _vm.arrayColumn = (array, column) => {
        return array.map(x => x[column])
    };

    _vm.objectPath = objectPath;

    _vm.setCurrentPageBreadcrumbs = setCurrentPageBreadcrumbs;
    _vm.setCurrentPageTitle = setCurrentPageTitle;

    _vm.showModal = showModal;
    _vm.hideModal = hideModal;
    _vm.removeModalBackdrop = removeModalBackdrop;

    _vm.$swal = Swal;
    _vm.endpoint = Endpoint;

    _vm.$moment = moment;

    _vm.eventBus = eventBus;

    _vm.onResponse = (response, successCallback, callback, showSuccessNotify = true, runCallbackInSuccess = true) => {
        if (!response.status) {
            return _vm.onResponseFailure(response, callback);
        }

        if (showSuccessNotify) {
            _vm.$notify({
                type: 'success',
                title: _vm.$t("messages.success"),
                message: _vm.$t("messages.actionOk"),
            });
        }

        if (successCallback) {
            successCallback();
        }

        if (callback && runCallbackInSuccess) {
            callback();
        }
    };

    _vm.onResponseFailure = (response, callback) => {
        _vm.$notify({
            type: 'error',
            title: _vm.$t("messages.error"),
            message: response.message,
        });

        if (callback) {
            callback();
        }
    };

    _vm.resolveDatum = (data, value, key = 'id', defaultValue = {}) => {
        if (!data || !value) {
            return defaultValue;
        }

        let datum = defaultValue;

        for (let i = 0; i < data.length; i++) {
            if (_vm.objectPath.get(data[i], key) == value) {
                datum = data[i];
                break;
            }
        }

        return datum;
    }

    _vm.checkTreeData = (data) => {
        return data.map((item) => {
            if (item.children.length) {
                item.children = _vm.checkTreeData(item.children);
            } else {  //string
                delete item.children;
            }
            return item;
        });
    }

    _vm.cloneData = (data) => {
        return JSON.parse(JSON.stringify(data));
    };

    _vm.ucFirst = (string) => {
        string = string.toLowerCase(string);
        return string[0].toUpperCase() + string.slice(1);
    };

    _vm.uniqueID = (length = 16) => {
        return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
    };

    _vm.bytesToSize = (bytes, decimals = 2) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        if (bytes == 0) {
            return '0 Byte';
        }

        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

        return ((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    _vm.stringLimit = (string, limit = 100, end = '...') => {
        if(!string || typeof(string) != 'string'){
            return string;
        }

        if (string.length <= limit) {
            return string;
        }

        return string.substring(0, limit).trim().end;
    }

    _vm.trustedWindowOpen = (url, target) => {
        let a = document.createElement("a");
        document.body.appendChild(a);

        a.style = "display: none";
        a.href = url;

        if(target) {
            a.target = target;
        }

        a.click();
        document.body.removeChild(a);
    }

    _vm.objectToUrlQuery = (object, defaulValue = '', prefix = '?') => {
        let parameters = [];

        for(let key in object) {
            let parameter = object[key];

            if(Array.isArray(parameter)) {
                parameter.forEach((data) => {
                    parameters.push([(key + '[]'), data])
                });
            } else {
                parameters.push([key, parameter]);
            }
        }

        return (parameters.length ? (prefix + decodeURIComponent(new URLSearchParams(parameters).toString().toString())) : defaulValue);
    }

    _vm.urlParams = (key, defaultValue = null) => {
        let urlSearchParams = new URLSearchParams(window.location.search);
        const params = {};

        for (const key of urlSearchParams.keys()) {
            let regex = new RegExp(/\[]+$/)

            if(regex.test(key)) {
                params[key.replace(/\[]+$/, "")] = urlSearchParams.getAll(key);
            } else {
                params[key] = urlSearchParams.get(key);
            }
        }

        if(!key) {
            for (let paramKey in params) {
                let value = params[paramKey];

                if(Array.isArray(value)) {
                    params[paramKey] = value.map((data) => {
                        return _vm.urlParamValidateValue(data);
                    });
                } else {
                    params[paramKey] = _vm.urlParamValidateValue(value);
                }
            }

            return params;
        }

        if(params[key]) {
            return params[key];
        }

        return defaultValue;
    }

    _vm.urlParamValidateValue = (value) => {
        let newValue = value;

        if(['true', 'false'].includes(value)) {
            newValue = value == 'true';
        }

        if(value.trim().length && !isNaN(value)) {
            newValue = parseInt(value)
        }

        return newValue
    }

    _vm.toCamelCase = (string) => {
        return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => {
            return chr.toUpperCase();
        });
    }

    _vm.generateHexColor = () => {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    }
}