import {createTableState, normalizePagination} from '@/core/services/Helper'

const state = () => ({
    table: createTableState(),
    filterData: undefined
})

// getters
const getters = {}

// actions
const actions = {
    // get records
    async get({state, commit}, {page, filterData}) {
        commit('setLoading', true)

        state.filterData = filterData;

        let params = {
            page: page.current,
            page_limit: page.pageSize
        };

        if(state.filterData){
            params = {...params, ...state.filterData};
        }

        const res = await this._vm.axios.get(this._vm.endpoint.get('video_management_downloader', 'developer'), {
            params: params
        })

        const {data, meta} = res.data
        commit('updateTableData', data)
        commit('updatePagination', normalizePagination(meta))

        setTimeout(() => {
            commit('setLoading', false)
        }, 250)

        return data;
    },

    // refresh records
    refresh({state, dispatch}) {
        dispatch('get', {
            page: state.table.pagination,
            filterData: state.filterData
        })
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val
    },
    updatePagination(state, pagination) {
        state.table.pagination = pagination
    },
    updateTableData(state, data) {
        state.table.data = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
